@font-face {
  font-family: Fredericka;
  font-weight: normal;
  font-display: swap;
  src: url('./fonts/FrederickatheGreat-Regular.ttf')
}

$bg-color: #E1DECF;
$main-color: #8a7967;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
  font-family: Arial, sans-serif;
}

body {
  background-color: $bg-color;

  .container {
    max-width: 1300px;
    margin: 32px auto 70px auto;
    padding: 0 10px;
    position: relative;

    .header {
      display: flex;
      width: 100%;
      gap: 20px;
      justify-content: flex-end;
      padding: 0 30px;

      .logo {
        height: 90px;
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .left {
          max-height: 530px;

          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .right {
          max-width: 50%;
          text-align: justify;
        }
      }

      .music-box {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        max-width: 720px;
        margin-top: 32px;

        .top {
          display: flex;
          justify-content: space-between;

          .music-list {
            border: 2px solid $main-color;
            background: linear-gradient(to right, $main-color, $bg-color);
            color: #fff;
            max-width: 314px;

            .list-item {
              padding: 4px 8px;
              min-height: 30px;
              line-height: 30px;
              display: flex;
              flex-direction: column;
              transition: all 400ms;
              cursor: pointer;
              font-size: 14px;

              &.active {
                background-color: $main-color;
              }

              &:hover {
                background: $main-color;
              }

              &:not(:last-child) {
                border-bottom: 2px solid $main-color;
              }
            }
          }

          .img {
            width: 393px;
            display: flex;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .musics {
            display: flex;
            flex-direction: column;
            width: 393px;
            max-width: 393px;
            overflow-y: auto;
            max-height: 462px;
            border: 2px solid $main-color;
            background: #bdab98;
            padding: 4px 0;

            span {
              padding: 0 4px;
              color: $main-color;

              &:hover {
                background-color: $main-color;
                color: #fff;
                cursor: pointer;
              }

              &.active {
                background-color: $main-color;
                color: #fff;
              }
            }
          }

        }

        .options {
          display: flex;
          justify-content: space-between;
          background-color: $main-color;
          align-items: center;
          padding: 10px;

          .first {
            display: flex;
            gap: 6px;

            span {
              cursor: pointer;
              border-radius: 50%;
              background-color: #fff;
              width: 50px;
              height: 50px;
              text-align: center;
              line-height: 60px;

              svg {
                width: 20px;
                height: 20px;
                fill: $main-color;
              }
            }
          }

          .second {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .progress {
              background-color: $bg-color;
              height: 6px;
              width: 100%;
              max-width: 400px;

              span {
                background-color: #fff;
                display: block;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 730px) {
  body {
    .container {
      .header {
        justify-content: center;
      }

      .main {

        .info {
          flex-direction: column;

          .left {
            max-height: unset;
          }
          .right {
            max-width: unset;
          }
        }

        .music-box {
          .top {
            flex-direction: column;
            gap: 10px;

              .musics {
                max-width: unset;
                width: 100%;
              }

            .music-list {
              max-width: unset;
              width: 100%;
            }

            .img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 440px) {
  body {
    .container {
      margin-bottom: 200px;
      .header {
        flex-direction: column;
        align-items: center;
      }

      .main {
        .music-box {
          .options {
            width: 100%;
            position: fixed;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}


.rhap_container {
  background-color: $main-color !important;
  box-shadow: none !important;

  button {
    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  .rhap_progress-filled {
    background-color: $bg-color !important;
  }

  .rhap_progress-indicator {
    background-color: $bg-color !important;
  }

  .rhap_volume-indicator {
    background-color: $bg-color !important;
  }

  .rhap_time {
    color: #fff;
  }

  .rhap_additional-controls {
    display: none !important;
  }

}

::-webkit-scrollbar {
  width: 12px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: $bg-color;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $main-color;
}